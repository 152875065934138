<template>
  <EmTooltip force-tooltip :open-on-click="display.mdAndDown">
    <template #activator="{attrs, props}">
      <EmIcon :icon="icon" v-bind="{...attrs, ...$attrs, ...props}" />
    </template>
    <template #default>
      <span>
        {{ tooltip }}
      </span>
    </template>
  </EmTooltip>
</template>
<script setup>
import { useDisplay } from 'vuetify'
defineProps({
  icon: { type: String, required: true },
  tooltip: { type: String, default: '' }
})
const display = ref(useDisplay())
</script>
